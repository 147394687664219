import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import session from "./session";
import restaurantReducer from "./restaurants";
import reviewReducer from "./reviews";
import menuItemReducer from "./menuitems";
import cartItemReducer from "./cart";
import shoppingCartReducer from "./shoppingcart";
import foundRestaurantsReducer from "./restaurantSearch";
import awsReducer from "./aws";
import mapsReducer from "./maps";

const rootReducer = combineReducers({
	session,
	restaurant: restaurantReducer,
	review: reviewReducer,
	menuItem: menuItemReducer,
	cartItem: cartItemReducer,
	shoppingCart: shoppingCartReducer,
	searchResults: foundRestaurantsReducer,
	url: awsReducer,
	maps: mapsReducer,
});

let enhancer;

if (process.env.NODE_ENV === "production") {
	enhancer = applyMiddleware(thunk);
} else {
	const logger = require("redux-logger").default;
	const composeEnhancers =
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	enhancer = composeEnhancers(applyMiddleware(thunk, logger));
}

const configureStore = (preloadedState) => {
	return createStore(rootReducer, preloadedState, enhancer);
};

export default configureStore;
